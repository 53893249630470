import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'

import VueX from './js/vuex'
import Axios from 'axios'

import ChatPage from './pages/ChatPage'
import CreateChat from './pages/CreateChatPage'

Vue.config.productionTip = false

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', component: CreateChat, meta: { title: 'Create Chat' } },
    { path: '/Chat', component: ChatPage, meta: { title: 'Chat' } }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  next()
})

const ax = Axios
ax.defaults.baseURL = 'https://api.reeqzan.com'

Vue.prototype.$axios = ax

new Vue({
  render: h => h(App),
  router,
  store: VueX
}).$mount('#app')
