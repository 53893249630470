<template>
  <div class="create-chat-main">
    <input type="text" placeholder="Enter your name" @focus="isError = false" v-model="username" :disabled="creatingRoom">
    <input type="password" placeholder="Enter your password" @focus="isError = false" v-model="userpass" :disabled="creatingRoom">
    <input type="text" placeholder="Enter chat name" @focus="isError = false" v-model="chatname" :disabled="creatingRoom">
    <input type="password" placeholder="Enter chat password" @focus="isError = false" v-model="chatpass" :disabled="creatingRoom">
    <div v-if="isError" style="color: red; margin-bottom: 5px">{{ createError }}</div>
    <div v-if="creatingRoom" class="create-room-loading">
      <div class="spinner"></div>
      <div>Creating room . . .</div>
    </div>
    <button :disabled="creatingRoom" @click="createChat">Create Chat</button>

    <br />
    <div class="chat-list-title" v-if="chatList.length == 0">No available chats</div>
    <div class="chat-list-title" v-else style="display: flex; flex-direction: column; justify-content: center">
      <div style="text-align: center">Available Chats</div>
      <!-- <div style="display: flex; align-items: center">
        <input type="text" placeholder="Find Chat">
        <img class="search-image" src="https://img.icons8.com/nolan/64/search.png" height="25"/>
      </div> -->
    </div>
    <div v-if="loadingRoom" class="create-room-loading">
      <div class="spinner"></div>
      <div>Loading available room . . .</div>
    </div>
    <div v-for="(c, i) in chatList" :key="i" class="chat-list">
      <div style="width: 100%">
        <div class="chat-title">{{ c['chatname'] }}</div>
        <div class="chat-user">Created by: {{ c['user1name'] }}</div>
      </div>
      <button @click="startChatClicked(c)">Start Chat</button>
    </div>

    <div v-if="selectedChatRoom" class="start-popup">
      <div class="start-content">
        <div class="title">Starting chat in {{ selectedChatRoom['chatname'] }}</div>
        <input type="password" placeholder="Please enter your password" v-model="joinPass" @focus="startError = false">
        <div v-if="startError" style="margin: 3px 0; color: red">{{ startMessage }}</div>

        <button @click="nextClicked">Next</button>

        <img class="close-image" src="https://img.icons8.com/cute-clipart/50/000000/close-window.png" height="20"
          @click="selectedChatRoom = null; joinName = ''; joinPass = ''; startError = false"/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      username: '',
      userpass: '',
      chatname: '',
      chatpass: '',

      joinName: '',
      joinPass: '',

      chatList: [],

      isError: false,
      loadingRoom: false,
      creatingRoom: false,
      createError: '',
      startError: false,
      startMessage: '',

      selectedChatRoom: null
    }
  },
  props: {
  },
  methods: {
    createChat: async function() {
      if (!this.username || !this.userpass || !this.chatname || !this.chatpass) {
        this.isError = true;
        this.createError = 'Please fill in all the details above!';
        return;
      }

      this.creatingRoom = true;
      var roomExists = await this.$axios.get(`GetChatRoom/CheckIfRoomExists?room=${this.chatname}`);
      if (!roomExists.data['error']) {
        if (roomExists.data['hasRoom']) {
          this.isError = true;
          this.createError = 'Please use a different chat name';
          this.creatingRoom = false;
          return;
        }
      }

      var roomCreated = await this.$axios.post('/CreateChat/NewChat', {
        'username': this.username,
        'userpass': this.userpass,
        'chatname': this.chatname,
        'chatpass': this.chatpass
      });
      this.creatingRoom = false;

      if (roomCreated) {
        this.$axios.post('/Sender/SendUpdate', { method: 'ChatRoomCreated', data: {
          'username': this.username,
          'userpass': this.userpass,
          'chatname': this.chatname,
          'chatpass': this.chatpass
        }});
        this.username = '';
        this.userpass = '';
        this.chatname = '';
        this.chatpass = '';
      }
    },
    chatRoomCreated: function(e) {
      this.chatList.push({
        "user1name": e['chatname'],
        "user1pass": e['userpass'],
        "": "",
        "user2pass": e['chatpass'],
        "chatname": e['chatname']
      })
    },
    startChatClicked: function(e) {
      this.selectedChatRoom = e;
    },
    nextClicked: function() {
      if (!this.joinPass) {
        this.startError = true;
        this.startMessage = 'Please enter your password to start chat';
        return;
      }

      if (this.joinPass == this.selectedChatRoom['user1pass']) {
        this.selectedChatRoom['user1'] = true;
        this.$store.state.chatDetails = this.selectedChatRoom;
        this.$router.push('/Chat');
      } else if (this.joinPass == this.selectedChatRoom['user2pass']) {
        this.selectedChatRoom['user1'] = false;
        this.$store.state.chatDetails = this.selectedChatRoom;
        this.$router.push('/Chat');
      } else {
        this.startError = true;
        this.startMessage = 'Please enter your correct password to start the chat';
      }
    }
  },
  async mounted() {
    this.$store.commit('startSignal');
    this.$store.state.signal.on('ChatRoomCreated', this.chatRoomCreated);

    this.loadingRoom = true;
    var allChats = await this.$axios.get('/GetChatRoom/GetAllRooms');
    this.loadingRoom = false;
    this.chatList = allChats.data;
  },
  watch: {
  },
  destroyed() {
    this.$store.state.signal.off('ChatRoomCreated');
  }
}
</script>

<style scoped>
.create-chat-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.create-chat-main * {
  box-sizing: border-box;
}

input {
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
  max-width: 400px;
}

.chat-list-title {
  font-weight: bold;
  font-size: 1.1em;
}

.chat-list {
  border: 1px solid gray;
  box-shadow: 1px 1px 5px gray;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  width: 100%;
  max-width: 400px;
  align-items: center;
}

.chat-list .chat-title {
  font-weight: bold;
  font-size: 1.2em;
}

.chat-list .chat-user {
  font-size: 0.9em;
}

.chat-list button {
  height: fit-content;
  padding: 5px;
}

.create-room-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-room-loading .spinner {
  border-width: 3px;
  border-top-color: transparent;
  border-style: solid;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 3px;
  transition: 0.3s;
  animation: spinWithColor 4s linear infinite;
}

.search-image {
  cursor: pointer;
}

.start-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(64,64,64,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.start-popup .start-content {
  background-color: white;
  padding: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.start-content .close-image {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.start-content .title {
  font-weight: bold;
  font-size: 1.2em;
}

@keyframes spinWithColor {
  0% {
    border-color: red;
    border-top-color: transparent;
    transform: rotate(0deg);
  }
  25% {
    border-color: green;
    border-top-color: transparent;
    transform: rotate(360deg);
  }
  50% {
    border-color: blue;
    border-top-color: transparent;
    transform: rotate(720deg);
  }
  75% {
    border-color: yellow;
    border-top-color: transparent;
    transform: rotate(1080deg);
  }
  100% {
    border-color: red;
    border-top-color: transparent;
    transform: rotate(1440deg);
  }
}
</style>