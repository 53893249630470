<template>
  <div class="chat-box-main" :style="{ height: `${deviceHeight}px`, width: `${deviceWidth}px` }">
    <div class="chat-area" id="chatArea">
      <div class="chat-bubble" v-for="(c, i) in chats" :key="i" :style="{ alignSelf: c['msgFrom'] == myName ? 'flex-end' : 'flex-start',
        borderRadius: c['msgFrom'] == myName ? '10px 10px 0 10px' : '10px 10px 10px 0' }">
        <div class="small-text">{{ c['msgFrom'] }}</div>
        <div>{{ c['message'] }}</div>
        <div class="small-text" style="text-align: right">{{ c['date'] }}</div>
      </div>
      <div class="is-typing" v-if="userTyping">{{ userTyping['name'] }} is typing . . .</div>
    </div>
    <div class="send-area">
      <input type="text" v-model="msg" ref="msgInput" @keydown="msgKeyDown">
      <button @click="sendClicked">Send</button>
    </div>
    <div v-if="enterName" class="name-popup">
      <div class="popup-content">
        <input type="text" placeholder="Please enter your name" v-model="newName" @focus="newNameError = false">
        <div v-if="newNameError" style="color: red; text-align: center">Please enter your name!</div>
        <button @click="okayClicked">Okay</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      msg: '',
      myName: '',
      isUser1: false,
      hubName: '',

      enterName: false,
      newNameError: false,
      newName: '',

      userTyping: null,

      deviceHeight: 0,
      deviceWidth: 0,

      chats: []
    }
  },
  props: {
  },
  methods: {
    sendClicked: async function() {
      if (!this.msg) {
        return;
      }
      var date = new Date();
      var msgObj = {
        msgFrom: this.myName,
        msgTo: this.isUser1 ? this.$store.state.chatDetails['user2name'] : this.$store.state.chatDetails['user1name'],
        chatname: this.$store.state.chatDetails['chatname'],
        date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        message: this.msg
      };

      var addChat = await this.$axios.post('/Chat/AddChat', msgObj);

      if (addChat.data) {
        this.$axios.post('/Sender/SendUpdate', { method: `${this.hubName}Update`, data: msgObj });
        this.msg = '';
        this.$refs.msgInput.focus();
      }
    },
    chatUpdate: function(e) {
      this.chats.push(e);
      this.scrolToBottom();
    },
    scrolToBottom: function() {
      setTimeout(() => {
        var chatArea = document.getElementById('chatArea');
        chatArea.scrollTo(0, chatArea.scrollHeight);
      }, 10);
    },
    msgKeyDown: function(e) {
      if (e.key == 'Enter') {
        this.sendClicked();
      }
    },
    typing: function(e) {
      this.userTyping = e;

      if (e) {
        if (e['name'] == this.myName) {
          this.userTyping = null;
        }
      }
    },
    okayClicked: async function() {
      if (!this.newName) {
        this.newNameError = true;
        return;
      }

      var update = await this.$axios.get(`/Chat/UpdateUser?newName=${this.newName}&chatName=${this.$store.state.chatDetails['chatname']}`);
      if (update.data) {
        this.$store.state.chatDetails['user2name'] = this.newName;
        this.myName = this.newName;
        this.enterName = false;
        this.$refs.msgInput.focus();
      }
    }
  },
  async mounted() {
    if (!this.$store.state.chatDetails) {
      this.$router.push('/');
      return;
    }

    this.deviceHeight = window.innerHeight - 25;
    this.deviceWidth = window.innerWidth - 20;

    window.addEventListener('resize', (e) => {
      this.deviceHeight = e.target.innerHeight - 25;
      this.deviceWidth = e.target.innerWidth - 20;
    });

    this.isUser1 = this.$store.state.chatDetails['user1'];
    this.myName = this.isUser1 ? this.$store.state.chatDetails['user1name'] : this.$store.state.chatDetails['user2name'];
    if (!this.myName) {
      this.enterName = true;
    }

    var allChats = await this.$axios.get(`/Chat/GetChat?room=${this.$store.state.chatDetails['chatname']}`);
    this.chats = allChats.data.reverse();

    this.hubName = this.$store.state.chatDetails['chatname'].split(' ').join('');
    
    this.$store.state.signal.on(`${this.hubName}Update`, this.chatUpdate);
    this.$store.state.signal.on(`${this.hubName}Typing`, this.typing);

    this.scrolToBottom();
    this.$refs.msgInput.focus();
  },
  destroyed() {
    if (this.$store.state.signal) {
      this.$store.state.signal.off(`${this.hubName}Update`);
      this.$store.state.signal.off(`${this.hubName}Typing`);
    }
  },
  watch: {
    msg: function(val) {
      if (val) {
        this.$axios.post('/Sender/SendUpdate', { method: `${this.hubName}Typing`, data: { name: this.myName } });
      } else {
        this.$axios.post('/Sender/SendUpdate', { method: `${this.hubName}Typing`, data: null });
      }
    }
  }
}
</script>

<style scoped>
.chat-box-main {
  padding: 5px;
  position: relative;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat-box-main * {
  box-sizing: border-box;
}

.chat-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  position: relative;
}

.is-typing {
  position: sticky;
  bottom: 0;
  text-align: center;
  font-style: italic;
}

.chat-bubble {
  border: 1px solid black;
  padding: 0 5px;
  margin: 5px 0;
  width: fit-content;
  max-width: 60%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.small-text {
  font-size: 0.6em;
  margin: 3px 0;
}

.send-area {
  bottom: 5px;
  width: 100%;
  display: flex;
}

.send-area input {
  width: 100%;
  text-align: right;
  padding: 5px;
}

.name-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(64,64,64,0.3);
}

.name-popup .popup-content {
  background-color: white;
  padding: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.popup-content input {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 400px;
}
</style>